let timelineElements = [
  {
    id: 1,
    title: "IT Auditor",
    location: "Bakersfield, CA",
    company: "Valley Strong Credit Union",
    description: [
      "Recommend changes for reporting to enhance the overall monitoring of system, vulnerabilities and practices.",
      "Built data pipeline that allowed automated and efficient way of reporting using Powershell scripting, Excel , SQL and Power BI that increased efficiency of reports by 24%.",
      " Reviewed performance indicators and developed potential monitoring metrics.",
    ],
    // images: ["/images/n.jpg", "/images/n.jpg", "/images/n.jpg"],
    date: "01/2021 - Current",
    icon: "work",
    role: "IT Auditor",
  },
  {
    // //TODO  official title and role
    id: 2,
    title: "Student Web Developer",
    location: "Bakersfield, CA",
    company: "California State University, Bakersfield",
    images: ["/images/n.jpg", "/images/n.jpg", "/images/n.jpg"],

    description: [
      " Redesigned the whole Psychology Department Webpage using modern frameworks such as React.JS, HTML, CSS and Node.JS that resulted in 60% boost in attracting Psychology Major students.",
      "Developed according to the needs of Psychology Department Chair and consulted the best designs and implementation of such features",
    ],

    buttonText: "View Backend Projects",
    date: "01/2021 - 05/2021",
    icon: "work",
    role: ["Web Developer", "Web Designer", "Consultant"],
  },
  {
    id: 3,
    title: "Student Ambassador",
    company: "Microsoft",
    images: ["/images/n.jpg", "/images/n.jpg", "/images/n.jpg"],
    role: "IT Audit Technician",

    // location: "South Warren, Geshington",
    description: [
      " Represented Microsoft and organized community events that encouraged students to learn and develop their careers in technology.",
      "Advocated the use of technology with improving the quality of life, education, environment and more.",
    ],
    date: "01/2021 - Current",
    icon: "work",
  },

  {
    id: 4,
    title: "IT Admin",
    company: "Valley Strong Credit Union",
    images: ["/images/n.jpg", "/images/n.jpg", "/images/n.jpg"],

    // location: "South Warren, Geshington",
    role: "IT Audit Technician",

    description: [
      " Made production scripts for 600+ computers with 90% success rate.",
      "Administrator for emails,licenses,active directory,vid center/verafin, and phone system.",
      "Provided end-user troubleshooting and desktop support on Windows and Mac systems through KACE ticketing system.",
    ],
    date: "12/2019 - 12/2020",
    icon: "work",
  },
  {
    id: 4,
    title: "Software Engineering",
    location: "Virtual",
    company: "JP Morgan Chase",

    description: [
      "Modified existing graphing interface to correct coding errors, upgrade UI and improve overall performance.",
      "Adjusted and debugged source code for stock price data feed that has boosted computational efficiency by 35 percent.",
      "Developed with Python 3, node.js, C++ to produce efficient and validation-ready code for project specifications.",
    ],

    date: "09/2020 - 10/2020",
  },
  {
    id: 5,
    title: "Information Technology Assistant",
    location: "Bakersfield, CA",
    company: "California State University, Bakersfield",

    description: [
      "Used ServiceNow ticketing system to manage and process support actions and requests.",
      "Assisted students,faculty staff, and teachers via live chat, web conference and phone to resolve issues related to Blackboard,Office 365,and mycsub account issues.",
    ],
    date: "10/2019 - 12/2019",
  },
  {
    id: 6,
    title: "Bank Teller",
    company: "Valley Strong Credit Union",

    location: "Bakersfield, CA",

    description: [
      "Received cash and checks for deposit, including verifying amounts and endorsements and examining cash to prohibit acceptance of counterfeit bills.",
      " Helped customers open and close accounts, apply for loans and make sound financial decisions.",
      "Demonstrated expertise in identifying and mitigating potential fraud and transaction risks.",
    ],
    date: "10/2018 - 12/2019",
    icon: "school",
  },
];
const description = [
  {
    id: 1,
    description: [
      "Recommend changes for reporting to enhance the overall monitoring of system, vulnerabilities and practices",
      "Built data pipeline that allowed automated and efficient way of reporting using Powershell scripting, Excel , SQL and Power BI that",
      "Reviewed performance indicators and developed potential monitoring metrics.",
    ],
  },
];
export { description };
export default timelineElements;
