import "./App.css";
import { ReactComponent as WorkIcon } from "./work.svg";
import { ReactComponent as SchoolIcon } from "./school.svg";

import timelineElements from "./timelineElements";

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";

import "react-vertical-timeline-component/style.min.css";

function App() {
  let workIconStyles = { background: "#06D6A0" };

  return (
    <div>
      <h1 className="title">Work History</h1>
      <VerticalTimeline>
        {timelineElements.map((element) => {
          let isWorkIcon = element.icon === "work";
          let showButton =
            element.buttonText !== undefined &&
            element.buttonText !== null &&
            element.buttonText !== "";

          return (
            <VerticalTimelineElement
              key={element.key}
              date={element.date}
              dateClassName="date"
              iconStyle={workIconStyles}
              icon={<WorkIcon />}
            >
              <div
                className="titlejt"
                style={{ background: "rgb(6, 214, 160)", borderRadius: 40 }}
              >
                {/* <div className="middle2">
                  <i>
                    <h2
                      className="vertical-timeline-element-title"
                      style={{ fontWeight: "bold" }}
                    >
                      {element.title}
                    </h2>
                  </i>
                </div> */}
                {/* <div className="middle2">
                  <h4
                    style={{
                      fontWeight: "bold",
                      marginTop: 5,
                      marginBottom: 5,
                    }}
                    className="vertical-timeline-element-subtitle"
                  >
                    {element.location}
                  </h4>
                </div> */}

                <div className="text-center  ">
                  <div className="middle2">
                    <h2
                      style={{ color: "white", fontSize: 20 }}
                      className="vertical-timeline-element-title 
                    
                    "
                    >
                      <em> {element.title} </em>
                    </h2>
                  </div>

                  <div className="g mt-2 ">
                    <div className="middle2">
                      <p
                        style={{
                          color: "white",
                          fontSize: 15,
                          fontWeight: "bold",
                          marginBottom: 0,
                        }}
                        className="vertical-timeline-element-subtitle  rounded  "
                      >
                        <strong>
                          {" "}
                          <i> {element.company}</i>{" "}
                        </strong>
                      </p>
                    </div>

                    <div className="middle2">
                      <p
                        style={{
                          color: "white",
                          fontSize: 15,
                          fontWeight: "bold",
                          marginBottom: 0,
                          marginTop: 0,
                        }}
                        className="  "
                      >
                        <u>Location: {element.location}</u>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <p id="description">{element.description}</p> */}
              <ul>
                {element.description &&
                  element.description.map((desc) => {
                    return (
                      <li
                        style={{ marginTop: 10, marginBottom: 10 }}
                        key={desc}
                      >
                        {desc}
                      </li>
                    );
                  })}
              </ul>
            </VerticalTimelineElement>
          );
        })}
      </VerticalTimeline>
    </div>
  );
}

export default App;
